/* eslint-disable quotes */
export const appConfig =
{
  "apiKey": "AIzaSyDcf-tTD_4Iu_ccAaClLTcbr0FvmE92xWo",
  "appId": "1:250849616730:web:f3c41679fb83cf84f48963",
  "authDomain": "schooldog-i-harris-ga.firebaseapp.com",
  "measurementId": "G-C9F2HTL47W",
  "messagingSenderId": "250849616730",
  "project": "schooldog-i-harris-ga",
  "projectId": "schooldog-i-harris-ga",
  "showGaBranding": true,
  "storageBucket": "schooldog-i-harris-ga.appspot.com"
}
